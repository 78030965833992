<template>
  <b-card class="top-level">
    <b-form>
      <b-row align-v="end">
        <b-col
          class="px-2"
          cols="12"
          md="8"
        >
          <span class="text-nowrap flex-parent my-0">
            <h4 class="flex-child long-and-truncated">
              {{ product.name ? product.name : "Novo Produto" }}
            </h4>
          </span>
        </b-col>
        <b-col
          cols="12"
          md="4"
          align="right"
          class="py-1"
        >
          <b-row align-v="end">
            <b-col
              cols="12"
              md="6"
              class="mb-1 mb-md-0"
            >
              <b-button
                class="bg-white"
                variant="outline-success"
                :disabled="state === 'loading'"
                @click="saveProduct(false)"
              >
                {{
                  product.id
                    ? "Gravar e continuar editando"
                    : "Adicionar produto e continuar editando"
                }}
              </b-button>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <b-button
                variant="success"
                :disabled="state === 'loading'"
                @click="saveProduct(true)"
              >
                {{ product.id ? "Gravar" : "Adicionar produto" }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-card-actions
        title="Dados do produto"
        action-collapse
      >
        <b-row>
          <b-col
            cols="12"
            md="2"
          >
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="product.image"
                  :text="'Img'"
                  size="100px"
                  rounded
                />
              </template>
            </b-media>

            <div class="d-flex flex-wrap mb-1">
              <b-button
                variant="primary"
                class="px-1"
                @click="$refs.refInputEl.$el.childNodes[0].click()"
              >
                <b-form-file
                  ref="refInputEl"
                  v-model="newFile"
                  class="d-none"
                  @input="inputImageRenderer"
                />
                <feather-icon
                  icon="EditIcon"
                  class="d-inline"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1 px-1"
                @click="
                  () => {
                    product.image = '';
                    $refs.previewEl.$el.childNodes[0].src = '';
                  }
                "
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="10"
          >
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="product.name"
                    :state="productValidation.name.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ productValidation.name.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Preço"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="product.price"
                    type="number"
                    :state="productValidation.price.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ productValidation.price.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Preço com desconto"
                  label-for="discounted_price"
                >
                  <b-form-input
                    id="discounted_price"
                    v-model="product.discounted_price"
                    type="number"
                    :state="productValidation.discounted_price.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ productValidation.discounted_price.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Código do produto"
                  label-for="product_code"
                >
                  <b-form-input
                    id="product_code"
                    v-model="product.product_code"
                    type="text"
                    :state="productValidation.product_code.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ productValidation.product_code.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Slug"
                  label-for="slug"
                >
                  <b-form-input
                    id="slug"
                    v-model="product.slug"
                    type="text"
                    :state="productValidation.slug.state"
                    :disabled="state === 'loading'"
                  />
                  <b-form-invalid-feedback>
                    {{ productValidation.slug.feedback }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            md="12"
          >
            <label for="description">Descrição</label>
            <quill-Editor
              v-model="product.description"
              theme="snow"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Peso (kg)"
              label-for="weight"
            >
              <b-form-input
                id="weight"
                v-model="product.weight"
                type="number"
                :state="productValidation.weight.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.weight.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Peso liquido (kg)"
              label-for="net_weight"
            >
              <b-form-input
                id="net_weight"
                v-model="product.net_weight"
                type="number"
                :state="productValidation.net_weight.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.net_weight.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Largura (cm)"
              label-for="width"
            >
              <b-form-input
                id="width"
                v-model="product.width"
                type="number"
                :state="productValidation.width.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.width.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Altura (cm)"
              label-for="height"
            >
              <b-form-input
                id="height"
                v-model="product.height"
                type="number"
                :state="productValidation.height.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.height.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Comprimento (cm)"
              label-for="length"
            >
              <b-form-input
                id="length"
                v-model="product.length"
                type="number"
                :state="productValidation.length.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.length.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="NCM"
              label-for="ncm"
            >
              <b-form-input
                id="ncm"
                v-model="product.ncm"
                :state="productValidation.ncm.state"
                :disabled="state === 'loading'"
              />
              <b-form-invalid-feedback>
                {{ productValidation.ncm.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-card-actions
        title="Atributos do produto"
        action-collapse
      >
        <b-row>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.published"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Publicado
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.new"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Novo
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.featured"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Destaque
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.instant_payment_only"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Somente pagamento instantaneo
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.sale"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Promoção
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex mb-1">
              <b-form-checkbox
                v-model="product.free_shipping"
                name="q6-switch"
                switch
              />
              <p class="mb-0">
                Frete Grátis
              </p>
            </div>
          </b-col>

          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Categoria"
              label-for="category"
            >
              <v-select
                id="category"
                v-model="product.category"
                label="name"
                :options="$store.state.products.categoriesList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.category.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Textura"
              label-for="texture"
            >
              <v-select
                id="texture"
                v-model="product.texture"
                label="name"
                :options="$store.state.products.texturesList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.texture.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Nacionalidade"
              label-for="nationality"
            >
              <v-select
                id="nationality"
                v-model="product.nationality"
                label="name"
                :options="$store.state.products.nationalitiesList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.nationality.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Cores"
              label-for="colors"
            >
              <v-select
                id="colors"
                v-model="product.colors"
                label="name"
                multiple
                :options="$store.state.products.colorsList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.colors.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Tipos de colecionador"
              label-for="collector_type"
            >
              <v-select
                id="collector_type"
                v-model="product.collector_type"
                label="name"
                multiple
                :options="$store.state.products.collectorTypesList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.collector_type.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.attributes.attributeTypes.length === 0"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Tipo de acessório"
              label-for="tipo_acessorio"
            >
              <v-select
                id="tipo_acessorio"
                v-model="product.tipo_acessorio"
                label="name"
                multiple
                :options="$store.state.products.accessoryTypesList"
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.tipo_acessorio.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col
            v-for="attributeType in $store.state.attributes.attributeTypes"
            v-if="$store.state.attributes.attributeTypes"
            :key="attributeType.id"
            cols="12"
            md="6"
          >
            <b-form-group
              :label="attributeType.name"
              :label-for="attributeType.slug"
            >
              <v-select
                :id="attributeType.slug"
                v-model="attributes[attributeType.id]"
                label="name"
                multiple
                :options="
                  $store.state.attributes.productAttributesAll.filter(
                    (attribute) => attribute.attribute_type === attributeType.id
                  )
                "
                :reduce="(c) => c.id"
                :clearable="false"
                :disabled="state === 'loading'"
              >
                <template #no-options="{}">
                  A lista de opções está vazia
                </template>
              </v-select>
              <b-form-invalid-feedback>
                {{ productValidation.tipo_acessorio.feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-actions>

      <b-row>
        <b-col
          cols="12"
          align="right"
          class="mb-1"
        >
          <b-row
            align-v="end"
            align="right"
          >
            <b-col
              cols="12"
              md="9"
              class="mb-1 mb-md-0"
            >
              <b-button
                class="bg-white"
                variant="outline-success"
                :disabled="state === 'loading'"
                @click="saveProduct(false)"
              >
                {{
                  product.id
                    ? "Gravar e continuar editando"
                    : "Adicionar produto e continuar editando"
                }}
              </b-button>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <b-button
                variant="success"
                :disabled="state === 'loading'"
                @click="saveProduct(true)"
              >
                {{ product.id ? "Gravar" : "Adicionar produto" }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  VBPopover,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BForm,
  BFormCheckbox,
  BMedia,
  BFormInvalidFeedback,
  BTab,
  BTabs,
  BAlert,
  BLink,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    BTab,
    BTabs,
    BAlert,
    BLink,
    BFormTextarea,
    BFormFile,
    vSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BMedia,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    productId: {
      type: String,
      required: false,
      default: '-1',
    },
  },
  data() {
    return {
      attributes: {},
      newFile: null,
      newProduct: null,
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      validation: false,
      product: {
        image: null,
        name: null,
        price: null,
        discounted_price: null,
        product_code: null,
        description: null,
        slug: null,
        weight: null,
        net_weight: null,
        category: null,
        texture: null,
        nationality: null,
        colors: [],
        published: false,
        new: false,
        featured: false,
        instant_payment_only: false,
        sale: false,
        length: null,
        width: null,
        height: null,
        free_shipping: false,
        collector_type: [],
        tipo_acessorio: [],
        ncm: null,
      },
      validationBase: {
        status: {
          state: null,
          feedback: '',
        },
        image: {
          state: null,
          feedback: '',
        },
        name: {
          state: null,
          feedback: '',
        },
        price: {
          state: null,
          feedback: '',
        },
        length: {
          state: null,
          feedback: '',
        },
        width: {
          state: null,
          feedback: '',
        },
        height: {
          state: null,
          feedback: '',
        },
        discounted_price: {
          state: null,
          feedback: '',
        },
        product_code: {
          state: null,
          feedback: '',
        },
        description: {
          state: null,
          feedback: '',
        },
        slug: {
          state: null,
          feedback: '',
        },
        weight: {
          state: null,
          feedback: '',
        },
        net_weight: {
          state: null,
          feedback: '',
        },
        category: {
          state: null,
          feedback: '',
        },
        texture: {
          state: null,
          feedback: '',
        },
        nationality: {
          state: null,
          feedback: '',
        },
        colors: [],
        published: {
          state: null,
          feedback: '',
        },
        new: {
          state: null,
          feedback: '',
        },
        featured: {
          state: null,
          feedback: '',
        },
        instant_payment_only: {
          state: null,
          feedback: '',
        },
        sale: {
          state: null,
          feedback: '',
        },
        free_shipping: {
          state: null,
          feedback: '',
        },
        collector_type: {
          state: null,
          feedback: '',
        },
        tipo_acessorio: {
          state: null,
          feedback: '',
        },
        ncm: {
          state: null,
          feedback: '',
        },
      },
      productValidation: {
        status: {
          state: null,
          feedback: '',
        },
        image: {
          state: null,
          feedback: '',
        },
        name: {
          state: null,
          feedback: '',
        },
        price: {
          state: null,
          feedback: '',
        },
        length: {
          state: null,
          feedback: '',
        },
        width: {
          state: null,
          feedback: '',
        },
        height: {
          state: null,
          feedback: '',
        },
        discounted_price: {
          state: null,
          feedback: '',
        },
        product_code: {
          state: null,
          feedback: '',
        },
        description: {
          state: null,
          feedback: '',
        },
        slug: {
          state: null,
          feedback: '',
        },
        weight: {
          state: null,
          feedback: '',
        },
        net_weight: {
          state: null,
          feedback: '',
        },
        category: {
          state: null,
          feedback: '',
        },
        texture: {
          state: null,
          feedback: '',
        },
        nationality: {
          state: null,
          feedback: '',
        },
        colors: [],
        published: {
          state: null,
          feedback: '',
        },
        new: {
          state: null,
          feedback: '',
        },
        featured: {
          state: null,
          feedback: '',
        },
        instant_payment_only: {
          state: null,
          feedback: '',
        },
        sale: {
          state: null,
          feedback: '',
        },
        free_shipping: {
          state: null,
          feedback: '',
        },
        collector_type: {
          state: null,
          feedback: '',
        },
        tipo_acessorio: {
          state: null,
          feedback: '',
        },
        ncm: {
          state: null,
          feedback: '',
        },
      },
    }
  },
  computed: {},
  watch: {
    searchFilter(val) {
      this.pesquisa()
    },
  },
  created() {
    this.$store.dispatch('attributes/getAttributeTypes').then(
      () => {
        this.$store.dispatch(
          'attributes/getProductAttributesAll',
        )
      },
    ).catch(() => {})
    if (this.productId !== '-1') {
      this.state = 'loading'
      this.$store
        .dispatch('products/getProductById', this.productId)
        .then(() => {
          this.product = this.$store.state.products.productData
          this.$store
            .dispatch('attributes/getProductAttributesAll')
            .then(() => {
              const { attributes } = this.product
              const inital_att = {}
              for (const attributeId of attributes) {
                const attribute = this.$store.state.attributes.productAttributesAll.find(
                  attribute => attribute.id === attributeId,
                )
                if (attribute.attribute_type in inital_att) {
                  inital_att[attribute.attribute_type].push(attribute.id)
                } else {
                  inital_att[attribute.attribute_type] = [attribute.id]
                }
              }
              this.attributes = inital_att
            })
            .catch(() => {})
        })
        .finally(() => {
          this.state = 'ready'
        })
    }
    this.$store.dispatch('products/getCategories').catch(() => {})
    this.$store.dispatch('products/getTextures').catch(() => {})
    this.$store.dispatch('products/getColors').catch(() => {})
    this.$store.dispatch('products/getNationalities').catch(() => {})
    this.$store.dispatch('products/getCollectorTypes').catch(() => {})
    this.$store.dispatch('products/getAccessoryTypes').catch(() => {})
  },
  methods: {
    inputImageRenderer() {
      const file = this.newFile
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.product.image = reader.result
        },
        () => {},
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    saveProduct(goToProductsList) {
      this.product.attributes = Object.values(this.attributes).flat()
      const pro = JSON.parse(JSON.stringify(this.product))
      pro.description = JSON.stringify({
        delta: '',
        html: pro.description,
      })
      this.$store
        .dispatch('products/createOrUpdateProduct', pro)
        .then(response => {
          if (goToProductsList) {
            this.$router.push('/products')
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Produto',
                icon: 'SuccessIcon',
                variant: 'success',
                text: 'Produto gravado com sucesso!',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Produto',
                icon: 'SuccessIcon',
                variant: 'success',
                text: 'Produto gravado com sucesso!',
              },
            })
          }
        })
        .catch(error => {
          this.productValidation = JSON.parse(
            JSON.stringify(this.validationBase),
          )
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.productValidation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.productValidation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Produto',
              icon: 'DangerIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar este produto. Por favor verificar.',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
